var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "type",
                  attrs: {
                    placeholder: _vm.$t("applications.enter-pet-type"),
                    required: "",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.pet-type-required")
                      }
                    ],
                    icon: "pets",
                    label: "Type",
                    name: "type",
                    value: _vm.model.type
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("type", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "breed",
                  attrs: {
                    placeholder: _vm.$t("applications.pet-breed"),
                    required: "",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.pet-breed-required")
                      }
                    ],
                    icon: "pets",
                    name: "breed",
                    label: _vm.$t("applications.pet-breed")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("breed", $event)
                    }
                  },
                  model: {
                    value: _vm.model.breed,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "breed", $$v)
                    },
                    expression: "model.breed"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "weight",
                  attrs: {
                    label: _vm.$t("applications.pet-weight"),
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.pet-weight-required")
                      }
                    ],
                    required: "",
                    placeholder: _vm.$t("applications.enter-pet-weight"),
                    name: "weight",
                    value: _vm.model.weight
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("weight", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c(
                  "form-element",
                  { attrs: { label: _vm.$t("applications.spay-neutered") } },
                  [
                    _c("v-select", {
                      staticClass: "mt-2",
                      attrs: {
                        solo: "",
                        items: _vm.items,
                        value: _vm.model.neutered
                      },
                      on: {
                        change: function($event) {
                          return _vm.update("neutered", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "name",
                  attrs: {
                    label: _vm.$t("applications.pet-name"),
                    required: "",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.pet-name-required")
                      }
                    ],
                    placeholder: _vm.$t("applications.enter-pet-name"),
                    name: "name",
                    value: _vm.model.name
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("name", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "age",
                  attrs: {
                    label: _vm.$t("applications.pet-age"),
                    required: "",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.pet-age-required")
                      }
                    ],
                    placeholder: "Enter Age",
                    name: "age",
                    value: _vm.model.age
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("age", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }