<template>
    <div>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="type"
                    :placeholder="$t('applications.enter-pet-type')"
                    required
                    :rules="[
                        (v) => !!v || $t('applications.pet-type-required'),
                    ]"
                    icon="pets"
                    label="Type"
                    name="type"
                    :value="model.type"
                    @blur="update('type', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="breed"
                    :placeholder="$t('applications.pet-breed')"
                    required
                    :rules="[
                        (v) => !!v || $t('applications.pet-breed-required'),
                    ]"
                    icon="pets"
                    v-model="model.breed"
                    name="breed"
                    :label="$t('applications.pet-breed')"
                    @blur="update('breed', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="weight"
                    :label="$t('applications.pet-weight')"
                    :rules="[
                        (v) => !!v || $t('applications.pet-weight-required'),
                    ]"
                    required
                    :placeholder="$t('applications.enter-pet-weight')"
                    name="weight"
                    :value="model.weight"
                    @blur="update('weight', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-element :label="$t('applications.spay-neutered')">
                    <v-select
                        class="mt-2"
                        solo
                        :items="items"
                        @change="update('neutered', $event)"
                        :value="model.neutered"
                    ></v-select>
                </form-element>
            </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="name"
                    :label="$t('applications.pet-name')"
                    required
                    :rules="[
                        (v) => !!v || $t('applications.pet-name-required'),
                    ]"
                    :placeholder="$t('applications.enter-pet-name')"
                    name="name"
                    :value="model.name"
                    @blur="update('name', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="age"
                    :label="$t('applications.pet-age')"
                    required
                    :rules="[(v) => !!v || $t('applications.pet-age-required')]"
                    placeholder="Enter Age"
                    name="age"
                    :value="model.age"
                    @blur="update('age', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "applicant-pet",
    components: {},
    props: {
        modelData: {
            type: Object,
            required: true,
            validator: (value) => {
                const params = Object.keys(value);
                return [
                    "type",
                    "breed",
                    "id",
                    "weight",
                    "neutered",
                ].every((elem) => params.includes(elem));
            },
        },
    },
    data() {
        return {
            model: this.modelData,
            items: [
                {
                    text: "",
                    value: null,
                },
                {
                    text: this.$t("applications.yes"),
                    value: 1,
                },
                {
                    text: this.$t("applications.no"),
                    value: 0,
                },
            ],
        };
    },
    methods: {
        update(key, data) {
            this.model[key] = data;
            this.$emit("update", this.model);
        },
    },
    mounted() {},
    created() {},
    updated() {},
};
</script>

<style scoped>
</style>
